import React from 'react'
import PropTypes from 'prop-types'
// We need to use transitionalportal but it breaks stuff on build
// import { TransitionPortal } from 'gatsby-plugin-transition-link'

import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

import '../css/global.css'

export default function Layout ({ children }) {
  return (
    <div className="antialiased mx-auto">
      <NavBar />
      <main className="w-auto">
        {children}
      </main>
      <div className="breakup clearfix" />
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}
