import React from 'react'

export default function Footer() {
  const now = new Date()
  const thisYear = now.getFullYear()
  const thisMonth = now.getMonth()
  const thisDay = now.getDate()

  const LinkStyles = 'hoverShamal my-2'

  return (
    <footer className="px-8 lg:w-11/12 mx-auto">
      <ul className="font-medium mb-5">
        <li className={LinkStyles}>
          <a href="/">
            Home
          </a>
        </li>
        <li className={LinkStyles}>
          <a href="/terms-and-conditions">
            Terms &amp; Conditions
          </a>
        </li>
        <li className={LinkStyles}>
          <a href="/privacy-policy">
            Privacy Policy
          </a>
        </li>
        <li className={LinkStyles}>
          <a href="/get-in-touch">
            Get in touch
          </a>
        </li>
      </ul>
      <p className="text-center capitalize mb-8">Copyright &copy; {thisYear} shamal. all rights reserved.</p>
      <p className="print-only text-center">Accessed on {`${thisDay}-${thisMonth}-${thisYear}`}. Please visit https://shamalworkwear.com/ for the latest version.</p>
    </footer>
  )
}
