import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NavDiv from '../components/NavDiv'
import AnimatedLinkComponent from '../components/AnimatedLink'
import ShamalLogo from '../assets/img/logo.png'

export default class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navbarState: 'closed'
    }
  }

  toggleNavbar() {
    const { navbarState } = this.state

    this.setState({
      navbarState: navbarState === 'closed' ? 'opened' : 'closed'
    })
  }

  componentDidMount() {
    this.setState({ isMobile: window.innerWidth < 1024 })

    window.addEventListener(
      'resize',
      () => {
        this.setState({
          isMobile: window.innerWidth < 1024
        })
      },
      false
    )
  }

  render() {
    const { navbarState, isMobile } = this.state
    const NavbarClass =
      'w-full block flex-grow lg:flex lg:items-center lg:w-auto lg:text-right px-4 pt-4 lg:px-0 lg:pt-0 font-medium absolute lg:static bg-white z-30'

    if (isMobile) {
      return (
        <nav className="flex items-center justify-between flex-wrap w-full h-full bg-white mx-auto">
          <div className="flex items-center justify-between flex-wrap w-full h-full p-6 font-medium lg:w-11/12 bg-white z-40">
            <div className="flex items-center flex-shrink-0 text-white mr-6 ml-4">
              <AnimatedLinkComponent href="/">
                <img
                  src={ShamalLogo}
                  className="h-12 w-auto"
                  alt="Shamal Logo"
                  onClick={() => this.setState({ navbarState: 'closed' })}
                />
              </AnimatedLinkComponent>
            </div>
            <label
              className="block lg:hidden cursor-pointer flex items-center px-3 py-2 border rounded border-black-400"
              onClick={() => this.toggleNavbar()}
            >
              <svg
                className="fill-current h-5 w-5"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </label>
          </div>
          <NavDiv
            classes={NavbarClass}
            navbarState={navbarState}
            isMobile={isMobile}
          >
            <div
              className="text-sm lg:flex-grow px-6 pb-6"
              onClick={() => this.toggleNavbar()}
            >
              <AnimatedLinkComponent href="/our-story" styled activity firstItem>
                Our Story
              </AnimatedLinkComponent>
              <AnimatedLinkComponent href="/innovation" styled activity>
                Innovation
              </AnimatedLinkComponent>
              {/* <AnimatedLinkComponent href="/partners" styled activity>
                Partners
              </AnimatedLinkComponent> */}
              <AnimatedLinkComponent href="/get-in-touch" styled activity>
                Get in Touch
              </AnimatedLinkComponent>
              <AnimatedLinkComponent href="/book-a-demo" styled activity lastItem>
                Book a Demo
              </AnimatedLinkComponent>
            </div>
          </NavDiv>
        </nav>
      )
    }

    return (
      <nav className="flex items-center justify-between flex-wrap bg-white py-6 font-medium lg:w-11/12 mx-auto relative">
        <div className="flex items-center flex-shrink-0 text-white mr-6 ml-4">
          <AnimatedLinkComponent href="/">
            <img src={ShamalLogo} className="h-12 w-auto" alt="Shamal Logo" onClick={() => this.setState({ navbarState: 'closed' })}/>
          </AnimatedLinkComponent>
        </div>
        <label
          className="block lg:hidden cursor-pointer flex items-center px-3 py-2 border rounded border-black-400"
          onClick={() => this.toggleNavbar()}
        >
          <svg
            className="fill-current h-5 w-5"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </label>
        <NavDiv
          classes={NavbarClass}
          navbarState={navbarState}
          isMobile={isMobile}
        >
          <div className="text-sm lg:flex-grow" onClick={() => this.toggleNavbar()}>
            <AnimatedLinkComponent href="/our-story" styled activity firstItem>
              Our Story
            </AnimatedLinkComponent>
            <AnimatedLinkComponent href="/innovation" styled activity>
              Innovation
            </AnimatedLinkComponent>
            {/* <AnimatedLinkComponent href="/partners" styled activity>
              Partners
            </AnimatedLinkComponent> */}
            <AnimatedLinkComponent href="/get-in-touch" styled activity>
              Get in Touch
            </AnimatedLinkComponent>
            <AnimatedLinkComponent href="/book-a-demo" styled activity lastItem>
              Book a Demo
            </AnimatedLinkComponent>
          </div>
        </NavDiv>
      </nav>
    )
  }
}

NavBar.propTypes = {
  navbarState: PropTypes.bool
}
