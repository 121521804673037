import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
// add AniLink here to revert to old

const AnimatedLinkComponent = ({ children, href, styled, activity, className, firstItem, lastItem }) => {
  const activeClassName = activity ? 'currentNavLink' : null
  const defaultClassName = styled ? `block ${firstItem ? null : 'mt-4'} lg:inline-block lg:mt-0 hoverShamal ${lastItem ? null : 'mr-4'} inactiveNavLink` : null

  return (
    <Link
      to={href}
      duration={2}
      bg="#FADB31"
      activeClassName={activeClassName}
      className={className || defaultClassName }
    >
      { children }
    </Link>
  )
}

export default AnimatedLinkComponent

AnimatedLinkComponent.propTypes = {
  children: PropTypes.node,
  styled: PropTypes.bool,
  activity: PropTypes.bool,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  firstItem: PropTypes.bool,
  lastItem: PropTypes.bool
}
