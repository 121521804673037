import React from 'react'
import { useSpring, animated, config } from 'react-spring'
import PropTypes from 'prop-types'

export default function NavDiv({ children, classes, navbarState, isMobile }) {
  const animation = useSpring({
    marginTop: navbarState === 'closed' && isMobile ? '-7rem' : isMobile ? '8rem' : '0rem',
    opacity: navbarState === 'closed' && isMobile ? 0 : 1,
    config: config.stiff
  })

  return (
    <animated.div
      className={classes}
      style={animation}
    >
      {children}
    </animated.div>
  )
}

NavDiv.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
  navbarState: PropTypes.string,
  isMobile: PropTypes.bool
}
